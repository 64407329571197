"use-strict";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Icon from "../components/core_component/icon";
import { standardPlaneIcon } from "../components/assets/icons";
import Core from "../common/clientSdk";
import { ToastContainer, toast } from "react-toastify";
import { LinkCta, PrimaryCta, UnderLinedLinkCta } from "../components";
import { Loader } from "../screens/loader";
import { Util } from "reactstrap";
import Utils from "../common/utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function SubscriptionModal(props) {
  console.log("props ss", props);

  const [planChooseTab, setPlanChooseTab] = useState("tab-1");
  const [creditId, setCreditId] = useState("002");
  const [isLoading, setLoading] = useState(false);
  const [isLoadingSubs, setLoadingSubs] = useState(false);
  const [subscriptionSelected, setSubscriptionSelected] = useState();
  const [buySubscription, setBuySubscription] = useState(
    props?.subscriptionData ? props.subscriptionData[0] : {}
  );

  const navigate = useNavigate();
  const { subscriptionData = {}, orgData = {}, closeModal } = props;

  const creditAmount = orgData.credits;

  var redux_selector = useSelector((state) => {
    return {
      Auth: state.BrandUserAuthReducer,
      UserData: state.BrandUserDataReducer,
    };
  });

  const {
    user_data,
    brand_deep_data,
    org_deep_data = {},
  } = redux_selector.UserData;
  console.log("subscriptionSelected", redux_selector);

  useEffect(() => {
    getAutoSelectedTab();
    if (subscriptionData) {
      const firstPlanKey = Object.keys(subscriptionData)[0];
      if (firstPlanKey) {
        setSubscriptionSelected(subscriptionData[firstPlanKey]);
      }
    }
  }, [subscriptionData, setSubscriptionSelected]);

  function updateProgress(status) {
    setLoading(status);
  }

  function subscriptionUserCreate(plan_id) {
    var params = {
      org_id: orgData.org_id,
      plan_id: plan_id,
    };
    Core.subscriptionUserCreate(
      subscriptionUserCreateSuccess,
      subscriptionUserCreateFailed,
      (status) => setLoadingSubs(status),
      params
    );
  }
  function subscriptionUserCreateSuccess(response_data) {
    console.log("response_data  cre", response_data);
    toast.info("Contact support to purchase this plan");
    closeModal();
  }

  function subscriptionUserCreateFailed(errorList) {
    closeModal();
    toast.error(errorList);
  }
  function postCreditOrder() {
    var params = {
      c_id: creditId,
      redirect_url: window.location.origin + "/payment_success",
      platform: "web",
    };
    Core.postCreditOrder(
      postCreditOrderSuccess,
      postCreditOrderFailed,
      updateProgress,
      params
    );
  }
  function postCreditOrderSuccess(response_data) {
    const pgOrderId = response_data.credit_order_data.pg_order_id;
    redirectToPaymentPage(pgOrderId);
    setLoading(false);

    closeModal();
  }

  function postCreditOrderFailed(errorList) {
    closeModal();
    toast.error(errorList);
  }

  const phonePayGateWay = process.env.REACT_APP_API_PHONE_PAY_URL;

  function redirectToPaymentPage(pgOrderId) {
    // const paymentUrl = `https://mercury-t2.phonepe.com/transact/pg?token=${pgOrderId}`;
    const paymentUrl = `${phonePayGateWay}${pgOrderId}`;
    window.location.href = paymentUrl;
  }
  function tostFun() {
    closeModal();
    return toast.info("Contact support to purchase this plan");
  }
 
function creditPolicy(){
 
  window.open("/credit-policy", "_blank" );

}


  // const halfYearly = subscriptionData;
  // const {Half-Yearly ={}}=subscriptionData
  // console.log("subscriptionData", Object.keys(subscriptionData));
  function getAutoSelectedTab() {
    getSubscriptionTabData()?.forEach((item) => {
      setPlanChooseTab(
        getSubscriptionTabData()[0]["subscription_data"]["type"]
      );
    });
  }

  function renderStandardPlan(item) {
    return (
      <div className="subscription-container subscription-container-hover">
        <div>
          <div className="standard-plan-heading">
            <Icon iconName="standardPlaneIcon" />
            <div className="m-l-15">
              <p className="typography-14 m-b-4 pay-per-user-container">
                <span>Standard Plan</span>
                {item?.subscription_data?.name}
              </p>
              <h5 className="typography-25 standard-plan-amount">
                {Utils.convertFromINR(
                  item?.subscription_data?.price,
                  user_data.country
                )}
              </h5>
            </div>
            {!org_deep_data?.subscription_data?.plan?.plan_id && (
              <span className="typography-14  current-plan-active m-l-12">
                CURRENT PLAN
              </span>
            )}
          </div>
          <div className="all-row-plan-data">
            <div className="row-plan">
              <p className="typography-14-600">Free Credits</p>
              <p className="typography-14-600">{item.feature.free_credits}</p>
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Discount on Credits</p>
              <p className="typography-14-600">
                {" "}
                {item.feature.discount_on_credits} %
              </p>
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Dedicated Support</p>
              <Icon iconName="nonFillTick" />
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Influencer Discovery</p>
              <Icon iconName="nonFillTick" />
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Multi Brand</p>
              <Icon iconName="nonFillTick" />
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Fake Follower Check</p>
              <Icon iconName="nonFillTick" />
            </div>
          </div>
          <PrimaryCta
            invert
            className="buy-credits-btn height-42"
            onClick={() => postCreditOrder()}
            fontSize={"font_size_large"}
            isLoading={isLoading}
            text={"Buy Credits"}
          />
        </div>
      </div>
    );
  }

  function onPressHandlerOnPress(item, index) {
    // setBuySubscription(item);

    setPlanChooseTab(item?.subscription_data?.type);
  }

  function getSubscriptionTabData() {
    return (
      subscriptionData &&
      subscriptionData?.filter(
        (item) => item?.subscription_data?.type !== "Standard"
      )
    );
  }

  function getCurrentPlan(plan_id) {
    if (org_deep_data?.subscription_data?.plan?.plan_id == plan_id) {
      return true;
    } else {
      return false;
    }
  }

  function renderSubscriptionTabs() {
    return (
      <>
        {getSubscriptionTabData().map((item, index) => {
          return (
            <>
              {" "}
              <p
                key={index}
                className={
                  planChooseTab == item?.subscription_data.type
                    ? "tab-change-subscription-active"
                    : "tab-change-subscription"
                }
                onClick={() => onPressHandlerOnPress(item)}
              >
                {item?.subscription_data.type}
              </p>
            </>
          );
        })}
      </>
    );
  }

  function getSubscriptionListItems() {
    let subs_data = subscriptionData
      ?.filter(
        (item) =>
          item?.subscription_data.type == planChooseTab ||
          item?.subscription_data.type == "Standard"
      )
      .sort((a, b) => (a.subscription_data?.type === "Standard" ? -1 : 1));

    return subs_data;
  }

  function renderSubscriptionItem() {
    return (
      <>
        {getSubscriptionListItems()?.map((item) => {
          if (item?.subscription_data?.type == "Standard") {
            return renderStandardPlan(item);
          } else {
            return (
              <div className="subscription-container">
                <div className="standard-plan-heading">
                  <Icon
                    iconName={
                      !item.subscription_data?.type == "Half-Yearly"
                        ? "proPlaneIcons"
                        : "diamondIcon"
                    }
                  />

                  <div className="m-l-15">
                    <p className="typography-14 m-b-4">
                      {item?.subscription_data?.name}
                      {getCurrentPlan(item?.subscription_data?.plan_id) && (
                        <span className="typography-14 m-b-4 m-l-12 current-plan-active">
                          CURRENT PLAN
                        </span>
                      )}
                    </p>
                    <h5 className="typography-25">
                      {Utils.convertFromINR(
                        item?.subscription_data?.price,
                        user_data.country
                      )}
                    </h5>
                  </div>
                </div>
                <div className="all-row-plan-data">
                  <div className=" row-plan">
                    <p className="typography-14-600">Free Credits</p>
                    <p className="typography-14-600">
                      {item.feature.free_credits}
                    </p>
                  </div>
                  <div className=" row-plan">
                    <p className="typography-14-600">Discount on Credits</p>
                    <p className="typography-14-600">
                      {item.feature.discount_on_credits} %
                    </p>
                  </div>
                  <div className=" row-plan">
                    <p className="typography-14-600">Dedicated Support</p>
                    <Icon iconName="fillTickIcon" />
                  </div>
                  <div className=" row-plan">
                    <p className="typography-14-600">Influencer Discovery</p>
                    <Icon iconName="fillTickIcon" />
                  </div>
                  <div className=" row-plan">
                    <p className="typography-14-600">Multi Brand</p>
                    <Icon iconName="fillTickIcon" />
                  </div>
                  <div className=" row-plan">
                    <p className="typography-14-600">Fake Follower Check</p>
                    <Icon iconName="fillTickIcon" />
                  </div>
                </div>

                <PrimaryCta
                  invert
                  className="buy-credits-btn bg-color-buy-credit height-42"
                  onClick={() =>
                    subscriptionUserCreate(item?.subscription_data?.plan_id)
                  }
                  fontSize={"font_size_large"}
                  isLoading={isLoadingSubs}
                  text={"Subscribe"}
                />
              </div>
            );
          }
        })}
      </>
    );
  }

  return (
    <Modal isOpen={true} onRequestClose={closeModal} style={CancelReminder}>
      <div onClick={closeModal} className="close-btn-icon-subscription">
        <Icon iconName="CrossBtnIcon" />
      </div>

      <div className="plan-choose-main-container">
        <div className="top-section-choose-plan-container">
          <p className="pricing typography-12-500">Pricing</p>
          <h3 className="plan-choose-heading">Choose Your Pricing Plan</h3>
          <div style={{display:"flex",gap:"10px"}}>
          <p className="plan-choose-sub-heading">{creditAmount} Credits left</p>
          <div className="m-b-10  "  >
            <u style={{color:"#1977f2"}}>
               <LinkCta
                  p
                  textColor={"text_color_forgot"}
                  fontSize={"font_size_small"}
                  text={"How It Works?"}
                  onClick={() => creditPolicy()}
                />
             </u>
          </div>
          </div>
          <div className="plan-change-container">
            {subscriptionData && renderSubscriptionTabs()}
          </div>
        </div>

        <div className="subscription-main-container">
          {renderSubscriptionItem()}
        </div>
      </div>
    </Modal>
  );
}

export default SubscriptionModal;

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
    textAlign: "center",
    zIndex: "5",
    // height: "35%",
    display: "flex",
    flexDirection: "column",
    minWidth: "460px",
    minHeight: "150px",
    padding: "0px",
    borderRadius: 20,
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.75)",
    // filter: "blur(8px)",
    zIndex: 10,
  },
};
