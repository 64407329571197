import React, { useState } from "react";
import { CustomTextInput, UnderLinedLinkCta } from "../components";
import Modal from "react-modal";
import { CustomText, PrimaryCta} from "../components";
import Core from "../common/clientSdk";
import { useNavigate } from "react-router-dom";
import Utils from "../common/utils";
import Icon from "../components/core_component/icon";
import { ToastContainer, toast } from "react-toastify";

const VerifyOtpModal=({onRequestClose,setVerifyOtpModalOpen})=>{
    const [emailOtp, setEmailOtp] = useState("");
    const [loading, setLoading] = useState(false);

    var navigate = useNavigate();
    
    function updateProgress(status) {
        setLoading(status);
      }
      function postVerifyEmailOtpOrg(progress_cb) {
        console.log("postVerifyEmailOtpOrg")
        const params = {
          verification_code: emailOtp,
        };
        Core.otpVerification(
          postVerifyEmailOrgOtpSuccess,
          postVerifyEmailOrgOtpFailed,
          progress_cb,
          params
        );
        console.log("params",params)
      }
     
      console.log("emailOtp.length",emailOtp.length)
      function postVerifyEmailOrgOtpSuccess(response_data) {
        toast.success("Email verified Successfully")
        setVerifyOtpModalOpen(false)
        setLoading(false);
        // navigate("/settings");
        window.location.reload();
      }
    
      function postVerifyEmailOrgOtpFailed(errorList) {
        toast.error(errorList.map((item) => item.m).join(", "));
      }

      function postVerifyEmailOrg() {
        const params = {};
        Core.postVerifyEmailOrg(
          postVerifyEmailOrgSuccess,
          postVerifyEmailOrgFailed,
          () => {},
          params
        );
      }
    
      function postVerifyEmailOrgSuccess(response_data) {
        toast.success("Otp Sent");
      }
    
      function postVerifyEmailOrgFailed(errorList) {
        toast.error(errorList.map((item) => item.m).join(", "));
        toast.error("Otp Not Sent");
      }

    const customStyle = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          background: "var(--general-secondary-color)",
          borderColor: "var( --blue-blue-500)",
          textAlign: "center",
          zIndex: "5",
          maxHeight: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          minWidth: "400px",
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.86)",
          zIndex: 10,
        },
      };

   
    return(
        <div>
            <Modal  isOpen={true} onRequestClose={onRequestClose} style={customStyle}>
           <div className="header-modal-container">
          <CustomText
            p
            text={"Verify Email Address"}
            fontSize={"font_size_xl"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />
          <div onClick={onRequestClose} className="close-icon-brand-list">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>
        </div>
          <>
          <div >
              <div className="verify-otp ">
              <CustomText
                  p
                  fontSize={"font_size_m"}
                  text={`Enter OTP below`}
                  textColor={"text_color_primary"}
                  // fontWeight={"rubik_regular"}
                />
              </div>

              <div className="m-t-10 m-b-30 ">
                 <CustomTextInput
                   
                   newWidth={"login-right-side-top-input-field"}
                   maxLength={"6"}
                   title
                   placeholder="Enter OTP"
                   name="otp"
                   onChange={(e)=>setEmailOtp(e.target.value)}
                          />
              </div>
              <div className="m-t-15 full-width">
            <PrimaryCta
                fifth
                className="login-right-side-input-field"
                fullWidth100
                fontSize={"font_size_large"}
                text={"Verify"}
                disabled={ emailOtp.length<6 } // || !sent_notification
                isLoading={loading}
                onClick={() => {
                  postVerifyEmailOtpOrg(updateProgress);
                }}
             />
            </div>
            <div className="resent-email m-t-16">
                <CustomText
                  p
                  fontSize={"font_size_small"}
                  text={`Didn’t received the code?`}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />
                <div style={{color:"#1977f2"}}>
                    <UnderLinedLinkCta
                  fontSize={"font_size_small"}
                  textColor={"text_color_register"}
                  text={"Click to resend"}
                  onClick={() => postVerifyEmailOrg()}
                /></div>
                
              </div>
          </div>
          </>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        zIndex={9999999}
      />
        </div>
    )
}
export default VerifyOtpModal;